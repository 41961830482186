<template>
    <path d="m493.3 56.3-37.5-37.5c-12.6-12.6-28.9-18.8-45.3-18.8s-32.8 6.2-45.2 18.7l-352.4 352.4-12.7 114.2c-1.7 14.4 9.7 26.7 23.8 26.7 0.9 0 1.8 0 2.7-0.1l114.1-12.6 243.2-243.3 109.2-109.2c25-25 25-65.6 0.1-90.5zm-374.5 397.1-67.6 7.5 7.5-67.7 262.2-262.2 60.1 60.1-31 31-231.2 231.3zm340.5-340.6-44.3 44.3-60.1-60.1 44.3-44.3c4.1-4.1 8.8-4.7 11.3-4.7s7.2 0.6 11.3 4.7l37.5 37.5c6.2 6.2 6.2 16.4 0 22.6z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
